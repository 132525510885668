import React, { useContext, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { Trans } from '@lingui/react';
import debounce from 'lodash/debounce';
import MenuContent, {
  Title,
  Description,
  Items,
  Content,
  Element,
  Anchor,
} from './MenuContent';
import Subdropdown from './SubDropdown';
import { findParentElement } from '@lib/utils';
import LanguageContext from '@lib/contexts/languageContext';
import { WellnessBanner } from './PlayMenu';

const Menu = styled(MenuContent)`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${Content} {
    padding-top: 0;
    padding-bottom: 0;
  }

  ${Element}:nth-of-type(4) {
    display: none;
  }

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    width: calc(100vw - 5px);
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    ${Element}:not(:nth-of-type(4)) {
      padding: 32px 0;
    }

    ${Element}:nth-of-type(4) {
      display: flex;
      margin: 0;
      max-width: 380px;
    }

    ${Items} {
      max-width: 1280px;
      width: 100%;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  @media (min-width: ${({ theme }) => theme.viewports.desktop}px) {
    ${Items} {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Column = styled.div`
  & + & {
    // margin-top: 25px;
  }
`;

export const BannerBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  max-width: 320px;
  width: 100%;
  border-radius: 3px;
  padding: 24px 20px;
  margin-bottom: 14px;
  background:
    linear-gradient(to left, rgba(17, 158, 221, 0.7), rgba(17, 158, 221, 0.7)),
    no-repeat url(/static/Program3-Thumbnail.jpg);
  background-size: cover;
`;

const links = {
  en: [
    [
      {
        title: <Trans id="global_menu.more.blog.title" />,
        desc: <Trans id="global_menu.more.blog.desc" />,
        link: `${process.env.DOMAIN}/en/blog`,
      },
      {
        title: <Trans id="global_menu.more.pricing.title" />,
        desc: <Trans id="global_menu.more.pricing.desc" />,
        link: `/se/pricing`,
      },
    ],
    [
      {
        title: <Trans id="global_menu.more.wellness.title" />,
        desc: <Trans id="global_menu.more.wellness.desc" />,
        link: '/se/friskvardsbidrag',
      },
      {
        title: <Trans id="global_menu.more.team.title" />,
        desc: <Trans id="global_menu.more.team.desc" />,
        link: `/en/team`,
      },
    ],
    [
      {
        title: <Trans id="global_menu.more.business.title" />,
        desc: <Trans id="global_menu.more.business.desc" />,
        subItems: [
          {
            text: <Trans id="global_menu.more.en_business.item1" />,
            link: `${process.env.DOMAIN}/en/business`,
          },
          {
            text: <Trans id="global_menu.more.en_business.item3" />,
            link: `${process.env.DOMAIN}/en/business/yogalarare-en`,
          },
          {
            text: <Trans id="global_menu.more.en_business.item2" />,
            link: `${process.env.DOMAIN}/en/business/foretag-yoga-traning`,
          },
        ],
      },
    ],
  ],
  se: [
    [
      {
        title: <Trans id="global_menu.more.blog.title" />,
        desc: <Trans id="global_menu.more.blog.desc" />,
        link: `${process.env.DOMAIN}/se/blog`,
      },
      {
        title: <Trans id="global_menu.more.events.title" />,
        desc: <Trans id="global_menu.more.events.desc" />,
        link: `${process.env.DOMAIN}/se/marketplace/event`,
      },
      {
        title: <Trans id="global_menu.more.marketplace.title" />,
        desc: <Trans id="global_menu.more.marketplace.desc" />,
        link: `${process.env.DOMAIN}/marketplace`,
      },
      {
        title: (
          <Trans
            id="global_menu.more.retreats.title"
            message="Resor & retreats"
          />
        ),
        desc: (
          <Trans
            id="global_menu.more.retreats.desc"
            message="Hitta härliga destinationer med utvalda experter"
          />
        ),
        link: `${process.env.DOMAIN}/marketplace/travel`,
      },
    ],
    [
      {
        title: <Trans id="global_menu.more.pricing.title" />,
        desc: <Trans id="global_menu.more.pricing.desc" />,
        link: `${process.env.DOMAIN}/se/pricing`,
      },
      {
        title: <Trans id="global_menu.more.friskvårdsbidrag.title" />,
        desc: <Trans id="global_menu.more.friskvårdsbidrag.desc" />,
        link: `/se/friskvardsbidrag`,
        openInpage: true,
      },
      {
        title: <Trans id="global_menu.more.team.title" />,
        desc: <Trans id="global_menu.more.team.desc" />,
        link: `${process.env.DOMAIN}/se/team`,
      },
      {
        title: <Trans id="global_menu.more.partners.title" />,
        desc: <Trans id="global_menu.more.partners.desc" />,
        link: `${process.env.DOMAIN}/se/foretag/partners`,
      },
    ],
    [
      {
        title: <Trans id="global_menu.more.healthcare.title" />,
        desc: <Trans id="global_menu.more.healthcare.desc" />,
        link: `${process.env.DOMAIN}/se/healthcare`,
      },
      {
        title: <Trans id="global_menu.more.far.title" />,
        desc: <Trans id="global_menu.more.far.desc" />,
        link: `${process.env.DOMAIN}/se/c/far/b/far`,
      },
      {
        title: <Trans id="global_menu.more.business.title" />,
        desc: <Trans id="global_menu.more.business.desc" />,
        subItems: [
          {
            text: <Trans id="global_menu.more.se_business.item1" />,
            link: 'https://arbetsgivare.yogobe.com/',
          },
          {
            text: <Trans id="global_menu.more.se_business.item2" />,
            link: `${process.env.DOMAIN}/se/pausasmart`,
          },
          {
            text: <Trans id="global_menu.more.se_business.item3" />,
            link: `${process.env.DOMAIN}/se/foretag/yogalarare`,
          },
          {
            text: <Trans id="global_menu.more.se_business.item4" />,
            link: `${process.env.DOMAIN}/se/hotell`,
          },
        ],
      },
    ],
  ],
};

const MoreMenu = ({ className = '', open = false }) => {
  const ref = React.createRef<HTMLDivElement>();
  const theme = useTheme();
  const locale = useContext(LanguageContext);
  const lang = ['en', 'fi'].includes(locale) ? 'en' : 'se';

  const positionMenu = () => {
    const banner = ref.current;
    const li = findParentElement(banner, 'li');
    const { x } = li.getBoundingClientRect();
    banner.style.left = -x + 'px';
  };

  useEffect(() => {
    if (window.matchMedia(`(${theme.viewports.tablet}px)`)) {
      setTimeout(() => {
        positionMenu();
      }, 1000);

      const resizeHandler = debounce(
        () => {
          positionMenu();
        },
        400,
        { maxWait: 1000 }
      );

      window.addEventListener('resize', resizeHandler);
      return () => window.removeEventListener('resize', resizeHandler);
    }
  }, []);

  return (
    <Menu
      ref={ref}
      className={className}
      open={open}
      items={[
        ...links[lang].map((row, i) => {
          return (
            <Row key={i}>
              {row.map((el, i) => {
                let item = null;
                if (el.subItems) {
                  item = (
                    <Subdropdown
                      header={
                        <>
                          <Title>{el.title}</Title>
                          <Description>{el.desc}</Description>
                        </>
                      }
                      items={el.subItems}
                    />
                  );
                } else {
                  item = (
                    <Anchor
                      href={el.link}
                      target={el.openInpage ? '_parent' : '_self'}
                      prefetch={false}
                    >
                      <Title>{el.title}</Title>
                      <Description>{el.desc}</Description>
                    </Anchor>
                  );
                }
                return <Column key={i}>{item}</Column>;
              })}
            </Row>
          );
        }),
        ...(lang === 'se'
          ? [
              <div className="hidden h-full md:flex" key="banner">
                <WellnessBanner className="h-full" />
              </div>,
            ]
          : []),
      ]}
    />
  );
};

export default MoreMenu;
