import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/react';
import { t } from '@lingui/macro';
import * as Sentry from '@sentry/nextjs';
import LanguageContext from '@lib/contexts/languageContext';
import Spinner from '@components/Spinner';
import { ChoosePaymentMethodModal } from './ChoosePaymentMethodModal';
import Icon from '@components/Icon';
import { Button } from '@components/ButtonV2';
import { UserContext } from '@lib/contexts/UserProvider';
import useFlags from '@lib/hooks/useFlags';
import { AdyenCheckout, Dropin, Card, Trustly, Swish } from '@adyen/adyen-web';
import type {
  AdditionalDetailsData,
  UIElement,
  AdditionalDetailsActions,
} from '@adyen/adyen-web';
import { PaymentFailedBanner, ScaneSwishQRCode } from './PaymentFormBanners';
import {
  useMyPaymentMethodsQuery,
  PaymentMethod as StoredPaymentMethod,
  ProviderKind,
  MyPaymentMethodsQuery,
  AdyenAction,
} from '@gql/generated';

const BackButton = styled(Button)`
  order: -1;
  font-size: 16px;
  margin-bottom: 10px;

  i {
    margin-right: 10px;
  }
`;

const Loading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutrals.gray};
  text-align: center;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  cursor: progress;
`;

const AdyenContainer = styled.div`
  .adyen-checkout__payment-method {
    background-color: white;
  }

  .adyen-checkout-trustly__description-list {
    display: none;
  }
  .adyen-checkout__payment-method--trustly {
    overflow: hidden;

    .adyen-checkout__payment-method__image__wrapper.adyen-checkout__payment-method__image__wrapper--outline {
      height: 38px;
      width: 80px;
      border: 1px solid rgba(0, 27, 43, 0.17);
    }

    .adyen-checkout__payment-method__image {
      width: 80px;
      height: auto;
      transform: translateY(3px);
    }
  }

  .adyen-checkout__payment-method--card {
    .adyen-checkout__payment-method__image__wrapper {
      width: 50px;
      height: auto;

      .adyen-checkout__payment-method__image {
        width: 100%;
        height: auto;
      }
    }
  }

  .adyen-checkout__payment-method--swish {
    .adyen-checkout__payment-method__image__wrapper {
      width: 70px;
      height: 30px;
      overflow: hidden;

      .adyen-checkout__payment-method__image {
        width: 100%;
        height: auto;
        transform: translateY(-7px);
      }
    }
  }

  .adyen-checkout__payment-method__brands {
    height: auto;

    .adyen-checkout__payment-method__image__wrapper {
      height: 26px;
      width: 40px;

      img {
        width: auto;
        height: auto;
      }
    }
  }

  .adyen-checkout__payment-method.adyen-checkout__payment-method--selected {
    /* background-color: rgba(0, 0, 0, .03); */
    border-width: 2px;
  }

  .adyen-checkout__button {
    background-color: ${({ theme }) => theme.colors.primary['blue-2']};

    :hover {
      background-color: ${({ theme }) => theme.colors.primary['blue-1']};
    }
  }
`;

const PROVIDER_METHODS = {
  adyen: 'card',
  trustly: 'trustly',
  swish: 'swish',
} as const;

export type PaymentMethod = 'card' | 'swish' | 'trustly' | 'wellness';
export type PaymentMethods = Array<PaymentMethod>;

type GetAdyenConfigParams = {
  currency: string;
  locale: string;
  amount?: number;
  methods: PaymentMethods;
  storedPaymentMethod?: StoredPaymentMethod;
};

const getTranslations = (paymentMethods: PaymentMethods) => {
  let text = t`payment_modal.payment_method.submit_button`;

  if (paymentMethods.includes('trustly')) {
    text = t`payment_modal.payment_method.trustly.submit_button`;
  } else if (paymentMethods.includes('swish')) {
    text = t`payment_modal.payment_method.swish.submit_button`;
  }

  return {
    'sv-SE': {
      continueTo: text,
      'trustly.description1': '',
      'trustly.description2': '',
    },
    'no-NO': {
      continueTo: text,
      'trustly.description1': '',
      'trustly.description2': '',
    },
  };
};

function getAdyenLocal(local) {
  const locals = {
    se: 'sv-SE',
    no: 'no-NO',
    fi: 'fi-FI',
    en: 'en-US',
  };

  return locals[local] || 'en_US';
}

const getAdyenConfig = ({
  currency,
  locale,
  methods = [],
  amount = 0,
  storedPaymentMethod,
}: GetAdyenConfigParams) => {
  const paymentMethodsResponse = {
    groups: [],
    paymentMethods: [],
  };

  if (methods.includes('trustly') && currency === 'SEK') {
    paymentMethodsResponse.paymentMethods.push({
      name: 'Trustly',
      supportsRecurring: true,
      type: 'trustly',
    });
  }

  if (methods.includes('card')) {
    paymentMethodsResponse.groups.push({
      name: t`payment_modal.payment_method.card.label`,
      types: ['maestro', 'mc', 'visa', 'visadankort'],
    });
    paymentMethodsResponse.paymentMethods.push({
      name: t`payment_modal.payment_method.card.label`,
      brands: ['maestro', 'mc', 'visa', 'visadankort'],
      details: [
        {
          key: 'encryptedCardNumber',
          type: 'cardToken',
        },
        {
          key: 'encryptedSecurityCode',
          type: 'cardToken',
        },
        {
          key: 'encryptedExpiryMonth',
          type: 'cardToken',
        },
        {
          key: 'encryptedExpiryYear',
          type: 'cardToken',
        },
        {
          key: 'holderName',
          optional: true,
          type: 'text',
        },
      ],
      type: 'scheme',
    });

    if (storedPaymentMethod) {
      paymentMethodsResponse.paymentMethods =
        paymentMethodsResponse.paymentMethods.reverse();
    }
  }

  if (methods.includes('swish')) {
    paymentMethodsResponse.paymentMethods.push({
      name: 'Swish',
      type: 'swish',
    });
  }

  return {
    checkoutConfig: {
      clientKey: process.env.ADYEN_CLIENT_KEY,
      environment: process.env.ADYEN_ENVIRONMENT,
      paymentMethodsResponse,
      locale: getAdyenLocal(locale),
      translations: getTranslations(methods),
      showPayButton: true,
    },
    dropinConfig: {
      paymentMethodComponents: [Card, Trustly, Swish],
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          ...(storedPaymentMethod?.provider === 'adyen'
            ? {
                holderName: storedPaymentMethod.name,
                storedPaymentMethodId: storedPaymentMethod.id,
                lastFour: storedPaymentMethod.lastFour,
                type: String(storedPaymentMethod.variant).toLowerCase(),
                expiryMonth: storedPaymentMethod.expiryMonth,
                expiryYear: storedPaymentMethod.expiryYear,
                holderNameRequired: false,
              }
            : { holderNameRequired: true }),
          amount: {
            // value takes amount in cents
            // JS issue: decimals loses precision on some operations
            // so we round it, since we multiply by 100
            value: Math.round(amount * 100),
            currency,
          },
        },
      },
    },
  };
};

export interface AdyenFormInterface {
  handleAction(
    action: Pick<
      AdyenAction,
      | 'type'
      | 'authorisationToken'
      | 'httpMethod'
      | 'paymentData'
      | 'qrCodeData'
      | 'redirectData'
      | 'paymentMethodType'
      | 'url'
      | 'subtype'
      | 'token'
    >
  ): void;
}

export type onAdditionalPaymentDetails = (
  state: AdditionalDetailsData,
  component: UIElement,
  actions: AdditionalDetailsActions,
  closeDetailsModal?: () => void
) => void;

type AdyenFormProps = {
  loading: boolean;
  amount: number;
  currency: string;
  onSubmit?(
    arg0: flexibleObject,
    actions?: { handleAction(...params: any): void },
    instance?: any
  ): void;
  onAdditionalDetails: onAdditionalPaymentDetails;
  paymentError: boolean;
  enabledPaymentMethods: PaymentMethods;
  paymentEnabled?: boolean;
  showStoredMethods?: Boolean;
  onClose?(): void;
  chooseMethodButtonText?: React.ReactNode;
  wellnessForm?: React.ReactNode;
};

const AdyenForm = React.forwardRef((props: AdyenFormProps) => {
  const [showSwishScanBanner, setShowSwishScanBanner] = useState(false);
  const instanceRef = React.useRef(null);
  const {
    loading,
    amount,
    currency,
    onSubmit,
    onAdditionalDetails,
    paymentError,
    enabledPaymentMethods,
    showStoredMethods = true,
    onClose,
    // if false we only use the modal to select method or add new (no cvc prompt)
    paymentEnabled = true,
    //
    wellnessForm,
  } = props;

  const [flags] = useFlags();
  const [currentUser] = useContext(UserContext);
  const _enabledPaymentMethods = enabledPaymentMethods?.filter((method) => {
    if (
      method === 'trustly' &&
      (currency !== 'SEK' ||
        !currentUser?.canUseTrustly ||
        flags.disable_trustly)
    ) {
      return false;
    }

    return ['swish', 'wellness'].includes(method) ? currency !== 'EUR' : true;
  }) || ['card'];

  const locale = useContext(LanguageContext);
  const [showPaymentForm, setShowPaymentForm] = useState(!showStoredMethods);
  const [selectedStoredPaymentMethod, setSelectedStoredPaymentMethod] =
    useState<StoredPaymentMethod>(null);

  const { data, loading: paymentMethodsLoading } = useMyPaymentMethodsQuery({
    onCompleted(data) {
      const usableMethods = getUsablePaymentMethods(data.myPaymentMethods);
      if (usableMethods?.length === 0) {
        setShowPaymentForm(true);
      }
    },
  });

  //
  // const currency = _currency ?? (['en', 'fi'].includes(locale) ? 'EUR' : 'SEK');

  const getUsablePaymentMethods = useCallback(function (
    methods: MyPaymentMethodsQuery['myPaymentMethods']
  ) {
    return methods?.filter((item) => {
      if (
        item.provider === ProviderKind['Trustly'] &&
        flags.disable_saved_trustly_payments === true
      ) {
        return false;
      } else if (
        !_enabledPaymentMethods?.includes(PROVIDER_METHODS[item.provider])
      ) {
        return false;
      }

      return true;
    });
  }, []);

  async function handleOnSubmit(state, instance) {
    if (state.isValid) {
      const { type, storedPaymentMethodId, ...paymentMethod } =
        state.data.paymentMethod;

      const variables = {
        paymentMethod: { ...paymentMethod, providerType: type },
        paymentMethodId: storedPaymentMethodId,
        riskData: state.data.riskData,
        browserInfo: state.data.browserInfo,
      };
      onSubmit(variables, instance);
    }
  }

  function closeDetailsModal() {
    setShowPaymentForm(true);
  }

  async function setupAdyenCheckout() {
    // library uses window so we lazy load it

    let methods = _enabledPaymentMethods;

    // for now we only need to show card payments within adyen-dropin for cvc
    if (selectedStoredPaymentMethod || !currentUser?.canUseTrustly) {
      methods = ['card'];
    }

    const { checkoutConfig, dropinConfig } = getAdyenConfig({
      currency,
      amount,
      locale,
      methods,
      storedPaymentMethod: selectedStoredPaymentMethod,
    });

    const configuration = {
      ...checkoutConfig,
      onError: (error, _component) => {
        Sentry.captureException(error, {
          contexts: {
            details: {
              amount,
              currency,
            },
          },
        });
      },
      onSubmit: handleOnSubmit,
      onAdditionalDetails(...params) {
        setShowSwishScanBanner(false);
        onAdditionalDetails.apply(null, [...params, closeDetailsModal]);
      },
    };

    const checkout = await (window['AdyenCheckout'] as typeof AdyenCheckout)({
      ...configuration,
      countryCode: 'se',
      environment: 'test',
      onActionHandled(data) {
        if (data.componentType === 'swish' && data.actionDescription) {
          setShowSwishScanBanner(true);
        }
      },
      onChange(_, element) {
        // @ts-ignore types are wrong maybe ?
        if (element.data?.paymentMethod?.type !== 'swish') {
          setShowSwishScanBanner(false);
        }
      },
    });

    const instance = new Dropin(checkout, dropinConfig).mount(
      '#component-container'
    );
    instanceRef.current = instance;

    const el = document.getElementById('component-container');
    instance.mount(el);
  }

  useEffect(() => {
    setShowSwishScanBanner(false);
    if (showPaymentForm) {
      setupAdyenCheckout();
    }

    return () => {
      if (instanceRef.current) {
        instanceRef.current.unmount();
      }
    };
  }, [showPaymentForm]);

  const onContinueToPayment = (item: StoredPaymentMethod = null) => {
    if (item && (paymentEnabled === false || item?.provider === 'trustly')) {
      //
      onSubmit({
        paymentMethod: { providerType: item.provider },
        paymentMethodId: item.id,
        riskData: null,
        browserInfo: null,
      });
    } else {
      setSelectedStoredPaymentMethod(item);
      setShowPaymentForm(true);
    }
  };

  const myPaymentMethods = getUsablePaymentMethods(data?.myPaymentMethods);
  if (!showPaymentForm && myPaymentMethods?.length > 0) {
    let useOtherMethodButtonText = (
      <Trans id="payment_modal.use_other_methods.default_text" />
    );

    [
      {
        methods: ['trustly', 'card', 'wellness'] as const,
        text: (
          <Trans id="payment_modal.use_other_methods.card_trustly_epassi" />
        ),
      },
      {
        methods: ['trustly', 'card'] as const,
        text: <Trans id="payment_modal.use_other_methods.card_trustly" />,
      },
      {
        methods: ['swish', 'card', 'wellness'] as const,
        text: <Trans id="payment_modal.use_other_methods.card_swish_epassi" />,
      },
      {
        methods: ['swish', 'card'] as const,
        text: <Trans id="payment_modal.use_other_methods.card_swish" />,
      },
    ].some((item) => {
      if (
        item.methods.every((method) => _enabledPaymentMethods.includes(method))
      ) {
        useOtherMethodButtonText = item.text;
        return true;
      }
      return false;
    });

    return (
      <ChoosePaymentMethodModal
        amount={amount}
        currency={currency}
        paymentMethods={myPaymentMethods}
        onClose={onClose}
        onContinueToPayment={onContinueToPayment}
        disaplyAs="inline"
        useOtherMethodButtonText={useOtherMethodButtonText}
      />
    );
  }

  return (
    <div>
      {/* must stay wrapper in fragment because backbutton relys on order on parent */}
      {showPaymentForm && myPaymentMethods?.length > 0 ? (
        <BackButton
          appearance="dark"
          link
          onClick={() => {
            setShowPaymentForm(false);
          }}
        >
          <Icon name="ygb-icon-Back-arrow-ic" />
          <Trans id="metadata.go_back" />
        </BackButton>
      ) : null}
      {loading ||
      (paymentMethodsLoading &&
        !data?.myPaymentMethods &&
        showStoredMethods) ? (
        <Loading data-testid="AdyenFormModal--Loading">
          <div className="h-full flex items-center justify-center w-full aspect-square rounded border bg-neutral-300/80">
            <Spinner size={38} spinnerColor="#fff" />
          </div>
        </Loading>
      ) : null}
      {paymentError ? <PaymentFailedBanner /> : null}
      {showSwishScanBanner ? <ScaneSwishQRCode /> : null}
      <AdyenContainer
        id="component-container"
        data-testid="AdyenFormModal--Container"
      ></AdyenContainer>
      {wellnessForm}
    </div>
  );
});

export default AdyenForm;
