import styled from 'styled-components';
import { Button as ButtonV2 } from '@components/ButtonV2';

const Column = styled.div`
  flex: 1;
  color: black;
  text-align: center;
  line-height: 40px;
`;

export const Wrapper = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  padding: 18px 20px;
  background: white;
  z-index: 21;
  transition: transform 200ms ease;

  :after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
  }

  @media (min-width: ${(props) => `${props.theme.viewports.tablet}px`}) {
    :after {
      content: none;
    }
  }
`;

export const Nav = styled(Column)`
  text-align: left;
  & > ul {
    justify-content: space-between;
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    vertical-align: top;
    flex-wrap: nowrap;
    padding-right: 20%;
    max-width: 580px;
    & > li {
      vertical-align: middle;
      margin: 0;
      padding: 0;
      flex: 0 0 auto;
      line-height: 1;
      &:last-child {
        margin-right: 0;
      }
      & > a {
        display: block;
        padding: 0 3px;
        font-size: 13px;
        font-weight: 600;
        text-transform: none;
        text-decoration: none;
      }
    }
  }
  .tabletDown {
    display: none;
  }
  @media (max-width: ${(props) => `${props.theme.viewports.laptop}px`}) {
    flex: 0 auto;
    .tabletDown {
      display: flex;
      padding-right: 0;
      li > a {
        margin-right: 18px;
      }
    }
    .tabletUp {
      display: none;
    }
  }
`;

export const Logo = styled(Column)`
  flex: 0 0 auto;
  z-index: 1;

  i:before {
    font-size: 15px;
  }

  @media (min-width: ${({ theme }) => theme.viewports.mobile}px) {
    i:before {
      font-size: 20px;
    }
  }
`;

export const User = styled(Column)`
  flex: unset;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  margin-left: auto;
  margin-right: 0;
  z-index: 1;
`;

export const SearchComp = {
  Wrapper: styled.div<{ isMobile: boolean }>`
    display: ${(props) => (props.isMobile ? 'none' : 'flex')};
    position: absolute;
    left: 100%;
    right: 0;
    bottom: ${(props) => (props.isMobile ? null : '0')};
    top: ${(props) => (props.isMobile ? '100%' : '0')};
    height: ${(props) => (props.isMobile ? '50px' : null)};
    ${(props) =>
      props.isMobile
        ? `
      @media (max-width: ${props.theme.viewports.tablet}px) {
        display: flex;
        left: 0%;
      }
      .search-icon {
        left: 25px;
      }
      .search-close-icon {
        display: none;
      }
      .search-close-icon,
      .search-icon,
      .search-input {
        line-height: 50px;
      }
      .search-input {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
        border-top: 1px solid rgba(0, 0, 0, 0.05);
      }
    `
        : null};
  `,
  Input: styled.input`
    background: white;
    border: 0;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    padding-left: 70px;
    margin: 0;
    font-size: 16px;
    &:focus {
      border: 0;
      outline: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    &::-webkit-search-cancel-button {
      display: none;
    }
  `,
  IconWrapper: styled.div`
    flex: 0 0 auto;
    cursor: pointer;
    @media (max-width: ${(props) => `${props.theme.viewports.tablet}px`}) {
      margin-left: 10px;
    }
    ${
      '' /* @media (max-width: ${props => `${props.theme.viewports.tablet}px`}) {
      display: none;
    } */
    };
  `,
  Icon: styled.div`
    cursor: pointer;
    pointer-events: none;
    left: 30px;
    position: absolute;
    line-height: 76px;
    z-index: 3;
  `,
  CloseIcon: styled.div`
    opacity: 0.5;
    position: absolute;
    right: 30px;
    line-height: 76px;
    z-index: 3;
    cursor: pointer;
  `,
};

export const Transform = styled.div`
  transition: all ease-in 1s;
`;

export const Login = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;

    @media (max-width: ${(props) => `${props.theme.viewports.tablet}px`}) {
      margin-right: 0;
    }

    @media (min-width: ${(props) => `${props.theme.viewports.laptop}px`}) {
      margin-left: auto;
    }

    /* .loginMobileCTA {
      display: none;
    } */

    .loginCTA {
      padding: 8px 12px 8px 0;
    }

    @media (min-width: ${(props) => `${props.theme.viewports.mobile}px`}) {
      .loginCTA {
        padding: 8px 12px;
      }
    }
  `,
  AvatarWrapper: styled.div`
    /* flex: 0 auto; */
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 0px 5px;
    margin-left: 20px;
    transition: background-color 100ms ease-in;
    cursor: pointer;

    &:hover {
      background-color: ${(props) =>
        props.theme.colors.neutrals['white-darkest']};
    }

    .Avatar--Name {
      display: none;
    }

    @media (min-width: ${(props) => `${props.theme.viewports.mobile}px`}) {
      .Avatar--Name {
        display: inline-block;
      }
    }
  `,
  Avatar: styled.img`
    display: inline-block;
    height: 40px;
    width: 40px;
    object-fit: cover;
    vertical-align: middle;
    border: 2px solid ${({ theme }) => theme.colors.primary['blue-1']};
    border-radius: 50%;
    margin-left: 10px;
    margin-bottom: 0;
    background-size: cover;
    background-color: #eeeeee;
    background-position: center center;
    cursor: pointer;
  `,
};

export const Button = styled(ButtonV2)`
  color: #fff;
  line-height: 24px;
  white-space: nowrap;
  text-decoration: none;
  border-radius: 3px;
  padding: 8px 12px;
  margin: 0;
  background: #56b9e5;
  cursor: pointer;

  &:hover,
  &:active {
    background: #56b9e5;
  }

  :first-letter {
    text-transform: capitalize;
  }

  ${(props) =>
    props.link
      ? `
        background: transparent;
        color: #242424;
        font-weight: 600;
        margin-right: 10px;
        &:hover {
          background: #dedede;
        }
      `
      : ''};
`;

export const SuggestionsLayerCmp = styled.div`
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  padding: 0;
  border-top: 1px solid #dedede;
  padding-top: 10px;
  padding-bottom: 10px;
  background: white;
  padding-left: 60px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  max-height: 250px;
  overflow-x: scroll;
`;

export const SuggestionsLayerItemCmp = styled.div`
  display: block;
  padding: 15px 10px;
  cursor: pointer;
  font-size: 16px;
  .highlight {
    font-weight: bold;
    color: black;
  }
`;
